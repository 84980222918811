@tailwind base;
@tailwind components;
@tailwind utilities;

@import '~antd/dist/antd.css';

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Poppins-Bold',
    'Poppins-Black', 'Poppins-BlackItalic', 'Poppins-BoldItalic', 'Poppins-ExtraBold',
    'Poppins-ExtraBoldItalic', 'Poppins-ExtraLight', 'Poppins-ExtraLightItalic',
    'Poppins-Italic', 'Poppins-Light', 'Poppins-LightItalic', 'Poppins-Medium',
    'Poppins-MediumItalic','Poppins-Regular','Poppins-SemiBold', 'Poppins-SemiBoldItalic',
    'Poppins-Thin','Poppins-ThinItalic',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
    font-family: Poppins-Black;
    src: local('Poppins-Black'), url(/src/fonts/Poppins-Black.ttf) format("truetype");
}

@font-face {
    font-family: Poppins-BlackItalic;
    src: local('Poppins-BlackItalic'), url(/src/fonts/Poppins-BlackItalic.ttf) format("truetype");
}

@font-face {
    font-family: Poppins-Bold;
    src: local('Poppins-Bold'), url(/src/fonts/Poppins-Bold.ttf) format("truetype");
}

@font-face {
    font-family: Poppins-BoldItalic;
    src: local('Poppins-BoldItalic'), url(/src/fonts/Poppins-BoldItalic.ttf) format("truetype");
}

@font-face {
    font-family: Poppins-ExtraBold;
    src: local('Poppins-ExtraBold'), url(/src/fonts/Poppins-ExtraBold.ttf) format("truetype");
}

@font-face {
    font-family: Poppins-ExtraBoldItalic;
    src: local('Poppins-ExtraBoldItalic'), url(/src/fonts/Poppins-ExtraBoldItalic.ttf) format("truetype");
}

@font-face {
    font-family: Poppins-ExtraLight;
    src: local('Poppins-ExtraLight'), url(/src/fonts/Poppins-ExtraLight.ttf) format("truetype");
}

@font-face {
    font-family: Poppins-ExtraLightItalic;
    src: local('Poppins-ExtraLightItalic'), url(/src/fonts/Poppins-ExtraLightItalic.ttf) format("truetype");
}

@font-face {
    font-family: Poppins-Italic;
    src: local('Poppins-Italic'), url(/src/fonts/Poppins-Italic.ttf) format("truetype");
}

@font-face {
    font-family: Poppins-Light;
    src: local('Poppins-Light'), url(/src/fonts/Poppins-Light.ttf) format("truetype");
}

@font-face {
    font-family: Poppins-LightItalic;
    src: local('Poppins-LightItalic'), url(/src/fonts/Poppins-LightItalic.ttf) format("truetype");
}

@font-face {
    font-family: Poppins-Medium;
    src: local('Poppins-Medium'), url(/src/fonts/Poppins-Medium.ttf) format("truetype");
}

@font-face {
    font-family: Poppins-MediumItalic;
    src: local('Poppins-MediumItalic'), url(/src/fonts/Poppins-MediumItalic.ttf) format("truetype");
}

@font-face {
    font-family: Poppins-Regular;
    src: local('Poppins-Regular'), url(/src/fonts/Poppins-Regular.ttf) format("truetype");
}

@font-face {
    font-family: Poppins-SemiBold;
    src: local('Poppins-SemiBold'), url(/src/fonts/Poppins-SemiBold.ttf) format("truetype");
}

@font-face {
    font-family: Poppins-SemiBoldItalic;
    src: local('Poppins-SemiBoldItalic'), url(/src/fonts/Poppins-SemiBoldItalic.ttf) format("truetype");
}

@font-face {
    font-family: Poppins-Thin;
    src: local('Poppins-Thin'), url(/src/fonts/Poppins-Thin.ttf) format("truetype");
}

@font-face {
    font-family: Poppins-ThinItalic;
    src: local('Poppins-ThinItalic'), url(/src/fonts/Poppins-ThinItalic.ttf) format("truetype");
}

@font-face {
    font-family: Montserrat-Regular;
    src: local('Montserrat-Regular'), url(/src/fonts/Montserrat-Regular.ttf) format("truetype");
}

@font-face {
    font-family: Montserrat-SemiBold;
    src: local('Montserrat-SemiBold'), url(/src/fonts/Montserrat-SemiBold.ttf) format("truetype");
}

@font-face {
    font-family: Montserrat-Medium;
    src: local('Montserrat-Medium'), url(/src/fonts/Montserrat-Medium.ttf) format("truetype");
}

@layer components {
    .row{
        @apply flex flex-row
    }

    .col{
        @apply flex flex-col
    }

    .green-gradient {
        @apply bg-gradient-to-tr from-green to-light-green
    }

    .red-gradient {
        @apply bg-gradient-to-tr from-dark-red to-red
    }

    .orange-gradient {
        @apply bg-gradient-to-tr from-orange to-yellow
    }

    .blue-gradient {
        @apply bg-gradient-to-tr from-blue to-sky-blue
    }

    .purple-gradient {
        @apply bg-gradient-to-tr from-dark-purple to-light-purple
    }
}
